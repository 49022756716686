<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="margin: 0px">
                    <div class="card-header" style="display: none">
                        <h5 class="card-title">Último Débito</h5>
                    </div>
                    <div class="card-body" style="
              padding: 24px;
              margin-bottom: 10px;
              background-color: #ededed !important;
              color: #444 !important;
              padding-bottom: 12px;
              border-radius: 10px;
            " v-if="lastInvoice">
                        <h5 class="card-title">Últimos Débitos</h5>
                        <div class="row">
                            <div class="col-2">
                                <strong style="color: #000">N° NOTA/PARCELA</strong>
                                <p style="font-size: 15px">
                                    {{ lastInvoice.E1_NUM }}
                                </p>
                            </div>
                            <div class="col-2">
                                <strong style="color: #000">VENCIMENTO</strong>
                                <p style="font-size: 15px">
                                    {{ lastInvoice.E1_VENCREA }}
                                </p>
                            </div>
                            <div class="col-2">
                                <strong style="color: #000">VALOR</strong>
                                <p style="font-size: 15px">
                                    {{ brNumber(lastInvoice.E1_VALOR) }}
                                </p>
                            </div>
                            <div class="col-2">
                                <strong style="color: #000">STATUS</strong>
                                <span
                                    v-if="(lastInvoice.E1_STATUS == 'A' && lastInvoice.E1_BAIXA.split('/')[0].trim() == '') && !estaVencido(lastInvoice.E1_VENCREA)"
                                    style="display: table !important; margin-top: 3px"
                                    class="badge light badge-warning">A Vencer</span>
                                <span
                                    v-if="(lastInvoice.E1_STATUS == 'A' || lastInvoice.E1_BAIXA.split('/')[0].trim() == '') && estaVencido(lastInvoice.E1_VENCREA)"
                                    style="display: table !important; margin-top: 3px"
                                    class="badge light badge-danger">Vencido</span>
                                <span
                                    v-if="(lastInvoice.E1_STATUS == 'B' || lastInvoice.E1_BAIXA.split('/')[0].trim() != '') && parseFloat(lastInvoice.E1_SALDO) == 0"
                                    style="display: table !important; margin-top: 3px"
                                    class="badge light badge-success">Baixado</span>
                                <span
                                    v-if="(lastInvoice.E1_STATUS == 'B' || lastInvoice.E1_BAIXA.split('/')[0].trim() != '') && parseFloat(lastInvoice.E1_SALDO) > 0"
                                    style="display: table !important; margin-top: 3px"
                                    class="badge light badge-info">Baixado Parcialmente</span>
                            </div>
                            <div class="col-4">
                                <div class="row" style="color: #000; text-transform: uppercase">
                                    <!-- Painel de Débitos Atrasados -->
                                    <div class="col-md-12">
                                        <span v-if="late_count == 0 ||
                                            late_count == null ||
                                            late_count == ''
                                        " style="display: block; font-size: 15px">Você não possui débitos em
                                            atraso.</span>
                                        <span v-else style="display: block; font-size: 15px">Você possui {{ late_count
                                            }}
                                            {{ late_count > 1 ? "débitos" : "débito" }} em atraso, no
                                            valor total de R$ {{ total_late }}</span>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <span v-if="open_count == 0 ||
                                            open_count == null ||
                                            open_count == ''
                                        " style="display: block; font-size: 15px">Você não possui débitos a
                                            vencer.</span>
                                        <span v-else style="display: block; font-size: 15px">Você possui {{ open_count
                                            }}
                                            {{ open_count > 1 ? "débitos" : "débito" }} a vencer, no
                                            valor total de R$ {{ total_open }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                                      <div class="col">
                                        <br />
                                        <strong style="font-size: 18px; color: #000"
                                          >Limite de Crédito Disponível:
                                        </strong>
                                        <span style="font-size: 18px">
                                          {{ limiteCredito }}
                                        </span>
                                      </div>
                                    </div> -->
                    </div>
                </div>
            </div>
        </div>
        <base-table v-if="loaded" :ref="table" :filters="filters" :crudName="crudName" :newText="newText" :table="table"
            :endPoint="endPoint" :enableAdd="false" :enableEdit="true" :enableSaveColumns="true" :enableDelete="true"
            :relationships="relationships" :extras="extras" :columns="columns" :enableExtract="true" :orders="orders"
            :styleHeader="'border-bottom: 5px solid #FF4C41'" @boleto="boleto" @danfe="danfe"></base-table>
        <div class="col-12" v-else>
            <div class="card-body text-center">
                <span class="fa fa-spinner fa-spin fa-5x"></span>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
import env from "@/env";

export default {
    data() {
        return {
            limiteCredito: "...",
            total_late: "...",
            total_open: "...",
            loaded: false,
            extras: [],
            pending_invoices: [],
            pending_invoices_total: 0,
            crudName: "Financeiro",
            orders: [
                {
                    name: "",
                    column: "",
                },
                {
                    name: "N° da Nota",
                    column: "E1_NUM",
                },
                {
                    name: "N° da Negociação",
                    column: "C5_MDCONTR",
                },
                {
                    name: "Data de Vencimento",
                    column: "E1_VENCREA",
                    selected: true,
                },
            ],
            filters: [
                {
                    id: "invoiceType",
                    type: "radio",
                    name: "status",
                    label: "Todos",
                    value: "all",
                    checked: true,
                },
                {
                    id: "invoiceType",
                    type: "radio",
                    name: "status",
                    label: "A Vencer",
                    value: "checkOpen",
                },
                {
                    id: "invoiceType",
                    type: "radio",
                    name: "status",
                    label: "Atrasados",
                    value: "checkOverdue",
                },
                {
                    id: "invoiceType",
                    type: "radio",
                    name: "status",
                    label: "Pagos",
                    value: "checkPayed",
                },
                {
                    id: "E1_NUM",
                    type: "text",
                    class: "form-control",
                    name: "E1_NUM",
                    label: "N° Nota/Parcela",
                    value: "",
                    columnSize: "col-3",
                },
                {
                    id: "C5_MDCONTR",
                    type: "contract",
                    class: "form-control",
                    name: "C5_MDCONTR",
                    label: "N° da Negociação",
                    value: "",
                    columnSize: "col-3",
                },
                {
                    name: "C6_NUMPCOM",
                    label: "Pedido do Cliente",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    id: "B1_DESC",
                    product_column: "B1_ESPECIE",
                    type: "product",
                    class: "form-control",
                    name: "B1_DESC",
                    label: "Produto",
                    value: "",
                    columnSize: "col-3",
                },
                {
                    id: "E1_VENCREA",
                    type: "date",
                    class: "form-control",
                    name: "E1_VENCREA",
                    label: "Data de Vencimento",
                    value: "",
                    columnSize: "col-3",
                },
                {
                    id: "returned",
                    type: "radio",
                    name: "returned",
                    label: "NF Devolvida",
                    value: "sim",
                    columnSize: "col-3",
                },
            ],
            newText: "",
            table: "invoice-list",
            endPoint: "invoices/",
            relationships: [],
            columns: [
                {
                    reportColumn: { COLUNA: 'NUM NOTA/PARCELA', CAMPO: 'VAZIO' },
                    label: "N° Nota/Parcela",
                    type: "buttonLabel",
                    callbackFunction: "danfe",
                    class: "btn btn-info btn-sm",
                    labelCallback: function (row) {
                        if (row.E1_PARCELA.trim() != "") {
                            return row.E1_NUM + " / " + row.E1_PARCELA;
                        }
                        return row.E1_NUM;
                    },
                    condition: function (row) {
                        if (row) {
                            return row.E1_NUM.trim() && row.C5_LOJAENT.trim() ? false : true;
                        }
                        return false;
                    },
                    conditionShow: function (row) {
                        if (row) {
                            return row.E1_NUM.trim() == "" ? false : true;
                        }
                        return true;
                    },
                },
                {
                    reportColumn: null,
                    label: "Forma de Pagamento",
                    type: "payment_method",
                    callbackFunction: "boleto",
                    class: "",
                    labelCallback: function (row) {
                        if (row.A1_XTIPDOC === "D" || row.A1_XTIPDOC.trim() === "") {
                            return 'Depósito Bancário';
                        } else if (row.A1_XTIPDOC === "B") {
                            return 'Boleto';
                        } else {
                            return 'Outro';
                        }
                    },
                    condition: function (row) {
                        const arr = ['237', '001', '341', '756', '033'];

                        if (row && arr.indexOf(row.E1_PORTADO) > -1 && row.A1_XTIPDOC === "B" && row.E1_BAIXA.split('/')[0].trim() == '') {
                            return false;
                        }
                        return true;
                        // return true;
                    },
                    conditionShow:

                        function (row) {
                            // if (row) {
                            //     if (env.isAmbientaly === true) {
                            //         return row.E1_NUMBCO.trim() == "" ? false : true;
                            //         // return row.E1_NUMBCO.trim() == "" ? true : true;
                            //     } else {
                            //         return row.E1_NUMBCO.trim() == "" || row.E1_PORTADO != '341' ? false : true;
                            //     }
                            // }
                            // if (row.E1_NUMBCO.trim() != "" && row.E1_BAIXA.split('/')[0].trim() != '')
                            //     return false;

                            return true;
                        }

                    ,
                },
                /*{
                    label: "Banco",
                    callback: function (row) {
                        if (!row.E1_NUMBCO.trim() == "") {
                            switch (row.E1_PORTADO) {
                                case "237":
                                    return "<img src='/banks/bradesco.png'>";
                                case "001":
                                    return "<img src='/banks/banco_do_brasil.png'>";
                                case "341":
                                    return "<img src='/banks/itau.png'>";
                                case "756":
                                    return "<img src='/banks/sicoob.png'>";
                                case "033":
                                    return "<img src='/banks/santander.jpg'>";
                                default:
                                    return "N/A";
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
                {
                    label: "Dados Bancários",
                    callback: function (row) {
                        if (row.dados_bancarios) {
                            const arr = row.dados_bancarios.split(" ");
                            return `
                                ${arr[0]} ${arr[1]}<br>
                                ${arr[2]} ${arr[3]}<br>
                                ${arr[4]}<br>
                                <span style="white-space: nowrap">${arr[5]}</span>`;
                        } else {
                            return "";
                        }
                    },
                },*/
                {
                    reportColumn: { COLUNA: 'STATUS', CAMPO: 'VAZIO' },
                    label: "Status",
                    callback: function (row) {
                        var vencto;
                        var today = moment();
                        if (row.E1_VENCTO.trim()) {
                            vencto = moment(row.E1_VENCTO, "DD/MM/YYYY");
                        }

                        var devolucao = row.D2_QTDEDEV;

                        if (devolucao > 0) {
                            return "<span class='badge bg-danger'>NF DEVOLVIDA</span>";
                        }

                        if (today.diff(vencto, "days") >= 1 && row.E1_STATUS == "A" && row.E1_BAIXA.split('/')[0].trim() == '') {
                            return "<span class='badge bg-danger'>Atrasado</span>";
                        }

                        if (row.E1_BAIXA.split('/')[0].trim() == '' && parseFloat(row.E1_SALDO) > 0) {
                            return "<span class='badge bg-info'>A Vencer</span>";
                        } else if (row.E1_BAIXA.split('/')[0].trim() != '' && parseFloat(row.E1_SALDO) > 0) {
                            return "<span class='badge bg-info'>Baixado Parcialmente</span>";
                        } else if (row.E1_BAIXA.split('/')[0].trim() != '' && parseFloat(row.E1_SALDO) == 0) {
                            return "<span class='badge bg-success'>Baixado</span>";
                        }

                        /*
                        switch (row.E1_STATUS) {
                            case "A":
                                return "<span class='badge bg-info'>A Vencer</span>";
                            case "B":
                                return "<span class='badge bg-success'>Baixado</span>";
                            case "R":
                                return "<span class='badge bg-success'>Baixado</span>";
                            default:
                                return "";
                        }
                        */
                    },
                },
                {
                    reportColumn: { COLUNA: 'DATA DE VENCIMENTO', CAMPO: 'E1_VENCREA' },
                    label: "Data de vencimento",
                    callback: function (row) {
                        if (row.E1_VENCREA.trim()) {
                            return moment(row.E1_VENCREA, "DD/MM/YYYY").format("DD/MM/YYYY");
                        }
                    }
                },
                {
                    reportColumn: { COLUNA: 'DIAS PARA VENCIMENTO', CAMPO: 'VAZIO' },
                    label: "Dias para vencimento",
                    callback:

                        function (row) {
                            var t = row.E1_STATUS;
                            if (t.trim() == "B") {
                                return 0;
                            }
                            if (t.trim() == "A") {
                                let t = moment(row.E1_VENCREA, "DD/MM/YYYY").diff(
                                    moment().format("YYYY-MM-DD"),
                                    "days"
                                );
                                return Math.floor(t);
                            }
                            let diff_in_days = moment(row.E1_VENCREA, "DD/MM/YYYY").diff(
                                moment().format("YYYY-MM-DD"),
                                "days"
                            );
                            return Math.abs(diff_in_days);
                        },
                },
                {
                    reportColumn: { COLUNA: 'DATA DO PAGAMENTO', CAMPO: 'VAZIO' },
                    label: "Data do pagamento",
                    callback: function (row) {
                        var E1_BAIXA = row.E1_BAIXA;
                        if (E1_BAIXA.trim() == "") {
                            return "N/A";
                        }

                        var baixa = row.E1_BAIXA.replaceAll("/", "").trim();

                        if (baixa == "") {
                            return baixa;
                        }
                        return moment(row.E1_BAIXA, "DD/MM/YYYY").format("DD/MM/YYYY");
                    },
                },
                {
                    reportColumn: { COLUNA: 'VALOR', CAMPO: 'VAZIO' },
                    label: "Valor",
                    callback: function (row) {
                        return new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        }).format(row.E1_VALOR);
                    },
                },
                // {
                //     label: "Parcela",
                //     callback: function (row) {
                //         if (row) {
                //             var E1_PARCELA = row.E1_PARCELA;
                //             if (E1_PARCELA.trim() != "") {
                //                 return E1_PARCELA;
                //             } else {
                //                 return "N/A";
                //             }
                //         } else {
                //             return "N/A";
                //         }
                //     },
                // },
                {
                    reportColumn: { COLUNA: 'COND. PAGAMENTO', CAMPO: 'E4_DESCRI' },
                    label: "Cond. pagamento",
                    callback: function (row) {
                        if (row) {
                            var E4_DESCRI = row.E4_DESCRI;
                            if (E4_DESCRI.trim() != "") {
                                return E4_DESCRI;
                            } else {
                                return "N/A";
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
                {
                    reportColumn: { COLUNA: 'PRODUTO', CAMPO: 'B1_DESC' },
                    label: "Produto",
                    callback: function (row) {
                        if (row.C6_PRODUTO.trim() != "") return row.B1_ESPECIE;
                        else return "N/A";
                    },
                },
                {
                    reportColumn: { COLUNA: 'NUM DO PEDIDO DO CLIENTE', CAMPO: 'C6_NUMPCOM' },
                    label: "N° do pedido do cliente",
                    callback: function (row) {
                        if (row) {
                            var C6_NUMPCOM = row.C6_NUMPCOM;
                            if (C6_NUMPCOM.trim() != "") {
                                return C6_NUMPCOM;
                            } else {
                                return "N/A";
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
                {
                    reportColumn: { COLUNA: 'FILIAL', CAMPO: 'M0_FILIAL' },
                    label: "Filial",
                    callback: function (row) {
                        if (row) {
                            var string = row.E1_FILIAL;
                            string = string.substring(string.indexOf(" - ") + 2);
                            return string;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    reportColumn: { COLUNA: 'NUM DO PEDIDO', CAMPO: 'C5_NUM' },
                    label: "N° do pedido",
                    callback: function (row) {
                        if (row) {
                            var C5_NUM = row.C5_NUM;
                            if (C5_NUM.trim() != "") {
                                return C5_NUM;
                            } else {
                                return "N/A";
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
                {
                    reportColumn: { COLUNA: 'NUM DA NEGOCIACAO', CAMPO: 'C5_MDCONTR' },
                    label: "N° da Negociação",
                    callback: function (row) {
                        if (row) {
                            var C5_MDCONTR = row.C5_MDCONTR;
                            if (C5_MDCONTR.trim() != "") {
                                return C5_MDCONTR;
                            } else {
                                return "N/A";
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
            ],
        };
    }
    ,
    computed: {
        lastInvoice() {
            if (this.pending_invoices != undefined) {
                if (this.pending_invoices.length > 0) {
                    return this.pending_invoices[this.pending_invoices.length - 1];
                }
            }

            return null;
        }
        ,
    }
    ,
    methods: {

        estaVencido(E1_VENCREA) {
            let diff_in_days = moment(E1_VENCREA, "DD/MM/YYYY").diff(
                moment().format("YYYY-MM-DD"),
                "days"
            );
            if (Math.abs(diff_in_days) > 0) {
                return false;
            }
            return true;
        },

        boleto(data) {
            const self = this;

            if (data.E1_NUMBCO) {

                if (data.E1_PORTADO == '237') {

                    var params = {
                        user_id: self.$store.getters.user.id,
                        num: data.E1_NUM,
                        parcela: data.E1_PARCELA,
                        banco: data.E1_PORTADO
                    };
                    params = JSON.stringify(params);
                    params = btoa(params);

                    this.$router.push("/admin/invoice-list/ticket/" + params);
                    // window
                    //     .open(
                    //         self.$store.state.api + "invoices/boleto?params=" + params,
                    //         "_blank"
                    //     )
                    //     .focus();
                } else {

                    const arr = data.E1_FILIAL.split('-');
                    var params = {
                        user_id: self.$store.getters.user.id,
                        filial: arr[0].trim(),
                        num: data.E1_NUM,
                        parcela: data.E1_PARCELA,
                        banco: data.E1_PORTADO
                    };
                    params = JSON.stringify(params);
                    params = btoa(params);
                    this.$router.push("/admin/invoice-list/ticket/" + params);
                }

            } else {
                alert("Boleto não disponível para esse título");
            }
        }
        ,
        danfe(data) {
            const self = this;

            if (data.E1_NUM.trim() && data.C5_LOJAENT.trim()) {
                data = btoa(
                    JSON.stringify({
                        nota_fiscal: data.E1_NUM.trim(),
                        loja_ent: data.C5_LOJAENT.trim(),
                        contrato: data.C5_MDCONTR.trim(),
                        filial: data.E1_FILIAL.trim(),
                    })
                );
                this.$router.push("/admin/invoice/danfe/" + data);
            } else {
                alert("DANFE não disponível para esse título");
            }
        }
        ,
        encode(string) {
            return btoa(string);
        }
        ,
        brNumber(number) {
            return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(number);
        }
        ,
        getLastInvoice() {
            const self = this;
            const api = this.$store.state.api + "invoices/lastInvoice";

            axios
                .get(api)
                .then((response) => {
                    self.pending_invoices = response.data.data.ZOOM;
                    self.late_count = response.data.late.count;
                    self.total_late = response.data.total_late;
                    self.total_open = response.data.total_open;
                    self.open_count = response.data.open.count;
                    self.pending_invoices_total = response.data.data.count;
                    self.getPaymentMethod();
                })
                .catch((error) => {
                    this.$toast.error("Erro na API");
                });
        }
        ,

        getPaymentMethod() {
            const self = this;
            const api = this.$store.state.api + "invoices/payment-method";

            axios
                .post(api)
                .then((response) => {
                    self.payment_method = response.data.ZOOM[0]["A1_XTIPDOC"];

                    setTimeout(function () {
                        if (self.payment_method == "B") {

                            // self.columns.splice(3, 1);

                            // temporario para testar boletos
                            // self.columns.splice(1, 1);
                            // self.columns.splice(1, 1);
                            // self.columns.splice(1, 1);
                            // self.columns.splice(7, 1);
                            // temporario para testar boletos

                            self.loaded = true;

                        } else {

                            // temporario para testar boletos
                            // self.columns.splice(3, 1);

                            // self.columns.splice(1, 1);
                            // self.columns.splice(2, 1);
                            // self.columns.splice(7, 1);
                            self.loaded = true;
                        }
                    }, 2000);
                })
                .catch((error) => {
                    this.$toast.error("Erro na API");
                });
        }
        ,
        getCreditLimit() {
            const self = this;
            const api = this.$store.state.api + "invoices/creditLimit";

            axios
                .get(api)
                .then((response) => {
                    self.limiteCredito = response.data.saldo;
                })
                .catch((error) => {
                    this.$toast.error("Erro na API");
                });
        }
        ,
    }
    ,
    mounted() {
        const self = this;
        // this.getPendingInvoices();
        // this.getCreditLimit();
        this.getLastInvoice();
    }
    ,
    components: {
        BaseTable,
    }
    ,
}
    ;
</script>
